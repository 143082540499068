import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "@components/layout"
import Seo from "@components/seo"
import Jobs from "@components/jobs/Jobs"

import "@styles/pages/jobs.scss"

const JobsPage = () => (
  <Layout>
    <Seo title="Jobs" />
    <div
      className="page-hero-image"
      style={{
        backgroundImage:
          "url(https://wordpress-742363-2492908.cloudwaysapps.com/wp-content/uploads/2018/07/jobs.gif)",
      }}
    >
      <div className="container-new">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "80vh" }}
        >
          <StaticImage
            src="https://wordpress-742363-2492908.cloudwaysapps.com/wp-content/uploads/2022/04/Group392-fixed.png"
            placeholder="none"
            alt="GET SHIT DONE"
          />
        </div>
      </div>
    </div>
    <div className="container-new py-65">
      <div className="row justify-content-between">
        <div className="col-12 col-md-6">
          <h2 className="font-55 font-HelveticaNeue-Light">
            WE ARE ALWAYS LOOKING FOR TALENTED HUMANS
          </h2>
        </div>
        <div className="col-12 col-md-4 offset-lg-1">
          <p className="font-22">
            If you’re well-qualified and can handle the gnar, send us your
            portfolio and resume. If the work is good and you’re cool maybe
            we’ll hire you.
          </p>
        </div>
        <div className="col-12 col-md-7 d-none d-md-block">
          <br />
          <br />
          <img
            src="/static-files/accordion-arrow-right.svg"
            width={100}
            height={35}
            style={{ display: "inline-block" }}
            alt=""
          />
        </div>
        <div className="col-12 col-md-4 font-22">
          <p>
            Email us at <br />
            <a href="mailto:inquire@sixteenfifty.com">
              inquire@sixteenfifty.com
            </a>
          </p>
        </div>
      </div>
    </div>
    <Jobs />
  </Layout>
)

export default JobsPage
