import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query jobsQuery {
      allWpJob {
        nodes {
          title
          content
          jobPosting {
            applyLink {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Fade direction="up" cascade triggerOnce>
      {data.allWpJob.nodes.map((job, index) => (
        <div className="job-listing" key={index}>
          <a href={job.jobPosting.applyLink.url} target="_blank">
            <div className="container-new">
              <div className="row">
                <div className="col-12">
                  <h2 className="uppercase font-50 font-HelveticaNeue-Light">
                    {job.title}
                  </h2>
                </div>
                {job.content && (
                  <div className="col-12 col-md-8">
                    <div
                      className="job-desc font-20"
                      dangerouslySetInnerHTML={{ __html: job.content }}
                    />
                  </div>
                )}
                {job.jobPosting.applyLink.url && (
                  <div className="col-12 col-md-4 d-flex align-items-end justify-content-end">
                    <div className="text-underline font-30">APPLY</div>
                  </div>
                )}
              </div>
            </div>
          </a>
        </div>
      ))}
    </Fade>
  )
}

export default Jobs
